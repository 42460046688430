













































































import { Component, Vue, Watch } from "vue-property-decorator";

import { AppSection, CurrentUserModel } from "@/shared/models";
import DateUtil from "@/shared/utils/DateUtil";
import { FunnelTemplateModel } from "@/templates/models/TemplateModel";

@Component
export default class FunnelTemplatesView extends Vue {
  readonly headers = [
    {
      text: this.$lang("template.name"),
      value: "name",
      width: "20%",
    },
    {
      text: this.$lang("template.description"),
      value: "description",
    },
    {
      text: this.$lang("template.createdAt"),
      value: "createdAt",
      width: "12%",
    },
    {
      text: this.$lang("template.owner"),
      value: "ownerName",
      width: "15%",
    },
    {
      text: this.$lang("accessType.title"),
      value: "accessType",
      width: "12%",
    },
    {
      text: this.$lang("segment.actions.title"),
      value: "action",
      align: "end",
      width: "10%",
      sortable: false,
    },
  ];
  search = "";
  showOnlyMy = false;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get templates(): Array<FunnelTemplateModel> {
    const funnelTemplates = this.$store.state.templateStore.funnelTemplates;

    return this.showOnlyMy
      ? funnelTemplates.filter(
          (template: FunnelTemplateModel) =>
            template.ownerId === this.currentUser.username
        )
      : funnelTemplates;
  }

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get isTemplatesLoading(): boolean {
    return this.$store.state.templateStore.isFunnelTemplatesLoading;
  }

  @Watch("applicationId", { immediate: true })
  watchRoute() {
    if (!this.$store.getters.isMultiAppMode) {
      return;
    }

    this.$router.push({
      name: AppSection.REPORT_TEMPLATES,
    });
  }

  goToFunnel(template: FunnelTemplateModel) {
    this.$router.push({
      name: "funnel_chart_view",
      params: {
        id: this.applicationId,
        funnelId: template.funnelId?.toString() ?? "",
      },
    });
  }

  getCreatedAtDate(item: FunnelTemplateModel) {
    return DateUtil.formatDate(item.createdAt);
  }

  showDeleteDialog(item: FunnelTemplateModel) {
    this.$emit("delete", FunnelTemplateModel.of(item));
  }
}
